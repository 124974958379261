/* General container */
.linktreePage {
    border-radius: 20px;
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: rgb(210, 232, 35);
    overflow: hidden;
  }
  
  .dashboardLine {
    position: fixed;
    left: 0px;
    height: 100vh;
    width: 30px;
    border-right: 1px solid black;
    z-index: 5;
  }
  
  .dashboardContainer {
    background-color: rgb(210, 232, 35);
    z-index: 6;
    padding-top: 40px;
  }
  
  /* Main Heading */
  .mainHeading {
    font-weight: 700;
    font-size: 32px;
    line-height: 2.25rem;
    margin-bottom: 1rem;
    letter-spacing: -1px;
    color: rgb(37, 79, 26);
  }
  
  .swipeContainer {
    height: 75px;
    overflow: hidden;
    width: 100%;
    color: rgb(59 130 246);
  }
  
  .swipeContent {
    animation: swipeUp 12s infinite;
  }
  
  .swipeItem {
    overflow: hidden;
    height: 75px;
    max-width: 300px;
    text-wrap: balance;
    line-height: 34px;
  }
  
  /* Swipe Animation */
  @keyframes swipeUp {
    0%, 15% {
      transform: translateY(0);
    }
    20%, 35% {
      transform: translateY(-20%);
    }
    40%, 55% {
      transform: translateY(-40%);
    }
    60%, 75% {
      transform: translateY(-60%);
    }
    80%, 95% {
      transform: translateY(-80%);
    }
  }
  
  /* Sub Heading */
  .subHeading {
    font-weight: 400;
    line-height: 1.5rem;
    font-size: 14px;
    font-family: "Plus Jakarta Sans", sans-serif;
    color: black;
    margin-bottom: 1.5rem;
  }
  .LoginForm {
    display: inline-flex;
    position: relative;
    height: 148px;
  }
  
  /* Form */
  .FormUsername {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: 0vw;
  }
  
  .FormUsername.animateForm {
    animation: FormUsernameProgress 0.5s forwards; /* Apply the animation when inputtingPassword is true */
  }
  
  .FormUsername.reverseForm {
    animation: FormUsernameReverse 0.5s forwards; /* Reverse animation when inputtingPassword is false */
  }
  
  @keyframes FormUsernameProgress {
    0% {
      left: 0vw;
    }
    100% {
      left: -100vw;
    }
  }
  
  @keyframes FormUsernameReverse {
    0% {
      left: -100vw;
    }
    100% {
      left: 0vw;
    }
  }
  
  .FormPassword {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    left: 100vw;
  }
  
  .FormPassword.animateForm {
    animation: FormPasswordProgress 0.5s forwards; /* Apply the animation when inputtingPassword is true */
  }
  
  .FormPassword.reverseForm {
    animation: FormPasswordReverse 0.5s forwards; /* Reverse animation when inputtingPassword is false */
  }
  
  @keyframes FormPasswordProgress {
    0% {
      left: 100vw;
    }
    100% {
      left: 0vw;
    }
  }
  
  @keyframes FormPasswordReverse {
    0% {
      left: 0vw;
    }
    100% {
      left: 100vw;
    }
  }
  
  
  .usernameLabel {
    font-size: 0.875rem;
    color: #444;
    margin-bottom: 5px;
    position: relative;
  }
  
  .usernameInput {
    width: 250px;
    height: 55px;
    padding-left: 10px;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #ccc;
    margin-top: 5px;
    margin-bottom: 15px;
  }

  .usernameInputError {
    width: 250px;
    height: 55px;
    padding-left: 10px;
    font-size: 1rem;
    background-color: #f0f0f0;
    border-radius: 5px;
    border: 2px solid red; /* Red border when error is true */
    margin-top: 5px;
    margin-bottom: 15px;
    
    /* Apply keyframe animation for border color transition */
    animation: borderTransition 2s ease-in-out forwards;
  }
  
  /* Keyframe animation for border color transition */
  @keyframes borderTransition {
    0% {
      border-color: red; /* Initial red border */
    }
    100% {
      border-color: transparent; /* Transition to transparent */
    }
  }

  .claimButton {
    width: 200px;
    height: 45px;
    background-color: #254F1A;
    color: #D2E823;
    text-align: center;
    font-size: 1rem;
    padding: 10px;
    border-radius: 30px;
    border: none;
    cursor: pointer;
  }
  
  .claimButton span {
    font-weight: 600;
  }
  
  /* Footer */
  .footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 2rem;
  }
  
  .footerLinks {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
  
  .footerLink {
    font-size: 0.875rem;
    color: #254F1A;
    margin-bottom: 0.5rem;
    text-decoration: underline;
  }
  
  .signupButton {
    background-color: transparent;
    border: 1px solid #254F1A;
    color: #254F1A;
    padding: 12px 30px;
    border-radius: 30px;
    text-align: center;
    font-size: 0.875rem;
    margin-top: 1.5rem;
    cursor: pointer;
  }
  
  .footerImage {
    flex: 1;
    text-align: center;
  }
  
  .footerImage img {
    width: 150px;
    height: 226px;
    margin-top: -50px;
  }
  
  .userInfo {
    width: 100vw;
    background-color: white;
    left: 0;
    position: fixed;
    border-radius: 20px 20px 0 0;
    bottom: 0;
    overflow: hidden;
    
    top: 75vh;
    transition: top 0.5s ease, padding 0.5s ease;
  }
  
.userInfoExpanded {
  width: 100vw;
  background-color: white;
  left: 0;
  position: fixed;
  border-radius: 20px 20px 0 0;
  bottom: 0;
  overflow: hidden;
  
  top: 15vh;
  transition: top 0.5s ease, padding 0.5s ease;
}
  .userInfoExpandButton {
    width: 100%;
    background-color: #d1ecdf;
    height: 30px;
    position: absolute;
  }


  .ItemContainer {
    height: 100%;
    overflow-y: auto;
    font-size: 10px;
  }

  .Item {
    background-color: #fff2a3;
    border-radius: 20px;
    margin: 20px;
    padding: 10px;
  }
  .transactionContainer {
    margin-left: 20px;
    margin-top: 10px;
  }
  
  .transaction {
    padding: 10px;
    margin-bottom: 5px;
    border-radius: 10px;
  }
  