/* Coupon container */
.coupon {
    display: flex;
    border: 2px solid #ccc;
    height: 50%;
    max-height: 140px;
    background-color: #f8f8f8;
    border-radius: 8px;
    font-family: Arial, sans-serif;
    align-items: center;
    position: relative;
    margin-bottom: 10px;
  }
  
  /* Left side (with the rotated code and dotted line) */
  .coupon-left {
    width: 80px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-right: 2px dotted #ccc;
    height: 100%;
  }
  
  .coupon-code {
    writing-mode: vertical-rl;
    font-size: 4vw;
    font-weight: bold;
    color: #333;
    margin: 0;
  }
  
  .dotted-line {
    position: absolute;
    left: 0;
    bottom: 10px;
    width: 60px;
    border-bottom: 2px dotted #ccc;
  }
  
  /* Right side (coupon details) */
  .coupon-right {
    padding: 10px;
    flex-grow: 1;
  }
  .coupon-value {
    font-size: 4vw; /* Minimum 18px, 6vw (responsive), Maximum 24px */
    font-weight: bold;
    color: #2c3e50;
    text-align: left;
  }
  
  .coupon-period,
  .coupon-expiration {
    font-size: 3vw;
    color: #7f8c8d;
  }
  
  
.RibbonBannerInverted {
  pointer-events: none;
  position: absolute;
  top: -38px;
  width: 170px;
  height: 170px;
  right: -30px;
  transform: scale(-0.8,0.8);
}

.RibbonBannerInverted img {
  object-fit: contain;
  width: 100%;
  height: auto;
}

.RibbonBannerInverted h1 {
  margin: 0;
  font-size: 17px;
  transform: rotate(-44.7deg) scale(-1, 1);
  transform-origin: center;
  white-space: nowrap;
  position: absolute;
  top: 58px;
  left: -8px;
}