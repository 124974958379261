.Invoice {
  overflow-x: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: rgba(88, 55, 50, 1);
  background-color: #e9e9e9;
}

.Invoice-title {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 6vw;
  color: rgba(88, 55, 50, 1);
  text-align: left;
  margin-left: 20px;
  margin-top: 7px;
}

.Invoice-detail {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;
  color: rgba(88, 55, 50, 1);
  text-align: left;
  margin-left: 20px;
  margin-top: 17px;
}

.PaymentOption {
  overflow: visible;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(88, 55, 50, 1);
  border-radius: 15px 15px 0 0;

  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
}

.PaymentOptionMargin {
  z-index: -1;
  overflow-x: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(88, 55, 50, 1);

  position: relative;
  height: 220px;
}

.TotalContainer {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.3em;
  padding: 10px 0;
  padding-top: 20px;
  /* margin-bottom: 17px; */
}

.PayButton {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;

  width: 80vw;
  height: 40px;
  border-radius: 50px;
  background-color: rgba(88, 55, 50, 1);
  color: white;
  border: none;
  margin: 0px auto;
  cursor: pointer;
  margin-bottom: 23px;
}

.PayButton div{
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.PayButton3{
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 20px;

  width: 100%;
  height: 40px;
  border-radius: 50px;
  background-color: rgba(88, 55, 50, 1);
  color: white;
  border: none;
  margin: 0px auto;
  cursor: pointer;
  margin-top: 10px;
}


.PayButton3 div{
  display: flex;
  justify-content: space-between;
  padding-left: 20px;
  padding-right: 20px;
}
.Pay2Button {
  text-align: center;
  color: rgba(88, 55, 50, 1);
  font-size: 1em;
  margin-bottom: 25px;
  cursor: pointer;
}

.Confirm {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.5em;
  padding: 10px 0;
  margin-bottom: 17px;
}

.RoundedRectangle {
  border-radius: 20px;
  padding-top: 5px;
  margin: 26px;
  background-color: #f9f9f9;
  margin-bottom: -20px;
}

.EmailContainer {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  font-size: 1em;
  padding: 10px 0;
  margin-bottom: 7px;
}
.OrderTypeContainer {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  font-size: 1em;
  padding: 10px 0;
  margin-bottom: 7px;
}

.Note {
  text-align: left;
  color: rgba(88, 55, 50, 1);
  font-size: 1em;
  margin-bottom: 13px;
  margin-left: 50px;
  cursor: pointer;
}

.NoteContainer {
  display: flex;
  justify-content: space-between;
  width: 80vw;
  margin: 0 auto;
  font-size: 1em;
  padding: 10px 0;
  margin-bottom: 7px;
  font-weight: 600;
}

.NoteInput {
  width: 78vw;
  height: 12vw;
  border-radius: 20px;
  margin: 0 auto;
  padding: 10px;
  font-size: 15px;
  border: 1px solid rgba(88, 55, 50, 0.5);
  resize: none; /* Prevent resizing */
  overflow-wrap: break-word; /* Ensure text wraps */
}


.AddedLastTransaction{
  width: 100%;
  font-size: 1em;
  padding: 10px 20px;
  margin-bottom: 7px;
  font-weight: 600;
}
.AddedLastTransaction div{
  display: flex;
  justify-content: space-between;
}
.CancelAddedLastTransaction{
  width: 40px;
  height: 40px;
  margin-right: 30px;
  margin-top: 10px;
  transform: rotate(45deg);
}