.item-type {
  width: calc(25vw - 20px);
  height: calc(30vw - 20px);
  margin: 1px 10px 0px;
  overflow: visible;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}
.item-type-rectangular {
  width: calc(25vw - 20px);
  height: calc(25vw - 20px);
  overflow: visible;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: start;
  position: relative; /* Ensure absolute positioning inside works */
}
.item-type-nomargin {
  width: calc(25vw - 20px);
  height: calc(39vw - 20px);
  overflow: visible;
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative; /* Ensure absolute positioning inside works */
}
.item-type-rect {
  position: relative;
  height: 13vw;
  width: 13vw;
  object-fit: cover;
  border-radius: 15px;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.item-type-name {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 14px;
  color: #333;
  width: calc(25vw - 30px);
  text-align: center;
  background-color: transparent;
  position: relative; /* Needed for positioning the button */
}

.item-type-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px;
}

.item-type-image-container {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
.item-type-image-input {
  opacity: 0;
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.item-type-create {
  position: absolute;
  top: 76%; /* Position below the input */
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px; /* Space between input and button */
  width: 20vw;
  text-align: center; /* Center button text */
}

.border {
  border: 1px solid #000000;
}

.noborder {
  border: 1px solid #ffffff00;
}
