/* Carousel.module.css */
.carouselContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.carousel {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  position: relative;
}

.carouselItem {
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 0.3s ease-in-out;
  height: 200px;
  background-color: #007bff;
  color: white;
  font-size: 24px;
  width: 100%;
  border-radius: 8px;
}

.carouselItem.prev,
.carouselItem.next {
  transform: scale(0.8);
}

.carouselItem.center {
  transform: scale(1);
  width: 134%;
}

.prevBtn,
.nextBtn {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: white;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 8px;
  cursor: pointer;
}

.prevBtn {
  left: 10px;
}

.nextBtn {
  right: 10px;
}

@media (max-width: 600px) {
  .carouselItem {
    font-size: 18px;
    height: 110px;
  }

  .prevBtn,
  .nextBtn {
    padding: 8px 15px;
    font-size: 14px;
  }
}
