.dropdown {
    position: relative;
    display: inline-block;
}

.dropdownButton {
    background-color: #fff;
    width: 150px;
    color: #000;
    border: 1px solid #000;
    cursor: pointer;
    font-size: 17px;
    border-radius: 6px;
}

.dropdownButton a {
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    display: flex;
    justify-content: space-between;
    line-height: 19px;
    font-size: 15px;
}

.dropdownButton i {
    margin-left: 8px;
    transition: transform 0.3s ease;
}

.dropdownContent {
    position: absolute;
    bottom: 105%;
    left: 0;
    background: #fff;
    width: 150px;
    border-radius: 6px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    border: 1px solid #000;
    z-index: 10;
}

.dropdownContent a {
    color: #000;
    padding: 8px 16px;
    text-decoration: none;
    display: block;
    transition: background-color 0.3s ease;
    border-bottom: 1px solid #ddd;
    display: flex;
    line-height: 19px;
    font-size: 17px;
}

.svg {
    width: 27px;
    height: 20px;
}
