@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

@import url("https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@200;300;400;500;600;700;800&ital,wght@0,200..800;1,200..800&display=swap");
html,
body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.App {
  /* overflow-x: hidden; */
}

.Cafe {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  position: relative;
}

.Cafe.grayscale {
  filter: grayscale(1);
  pointer-events: none; /* Disable pointer events when the deadline is exceeded */
}

.Watermark {
  position: absolute;
  height: 100%;
  width: 100%;
  background-image: url(https://i.ibb.co.com/F4FMw1jz/testuseonly.png);
  z-index: 1000;
  filter: opacity(0.05);
  pointer-events: none;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-top: 100px;
  margin-bottom: 100px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.title {
  font-family: "Plus Jakarta Sans", sans-serif;
  font-weight: 500;
  font-style: normal;
  font-size: 28px;
  color: rgba(88, 55, 50, 1);
  text-align: left;
  margin-left: 20px;
  margin-top: 17px;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
