.container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: white;
    color: black;
  }
  
  .loading {
    font-size: 24px;
    font-weight: bold;
  }
  