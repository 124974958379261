/* Chart item container */
.chartItemContainer {
  position: relative;
  font-size: 14px;
  margin-bottom: 230px;  /* Initial margin-bottom */
  transition: margin-bottom 0.3s ease-in-out;  /* Add transition for margin-bottom */
}

/* Expanded state - when the parent is "expanded" */
.expanded {
  margin-bottom: 503px;  /* New margin-bottom value when expanded */
}

.chartItemWrapper {
    position: absolute;
    transition: top 0.3s ease-in-out; /* Apply transition to chartItemWrapper */
    
    left: 20px;
    right: 20px;
  }
  .chartItemWrapperActive {
    top: 275px; /* Move chartItemWrapper down when selected */
  }
  
  .chartItemWrapperInactive {
    top: 0; /* Reset to original position */
  }
  
  .dateSelectorWrapper {
    display: flex;
    justify-content: space-around;
    position: relative;
    overflow: hidden;
    font-size: 14px;
  }
  
  .dateSelector {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .dateSelector:first-child {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    /* border-radius: 10px 0 0 0; */
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }
  
  .dateSelector:last-child {
    flex-grow: 1;
    text-align: center;
    padding: 10px;
    /* border-radius: 0 10px 0 0; */
    transition: all 0.3s ease-in-out;
    font-weight: 600;
  }

  .dateSelectorActive {
    color: black;
    z-index: 2;
  }
  
  .dateSelectorInactive {
    color: transparent;
    /* border-color: transparent; */
  }
  
  .chartWrapper {
    width: 100%;    /* This makes the chart responsive to its parent's width */
    height: 200px;  /* Set a fixed height for the chart */
  }
  